import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let VideoStoryblock = class VideoStoryblock extends Vue {
};
__decorate([
    Prop()
], VideoStoryblock.prototype, "block", void 0);
VideoStoryblock = __decorate([
    Component
], VideoStoryblock);
export default VideoStoryblock;
