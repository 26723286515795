import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let AudioStoryblock = class AudioStoryblock extends Vue {
    mounted() { }
};
__decorate([
    Prop()
], AudioStoryblock.prototype, "block", void 0);
AudioStoryblock = __decorate([
    Component
], AudioStoryblock);
export default AudioStoryblock;
