import { render, staticRenderFns } from "./VideoStoryBlock.vue?vue&type=template&id=48899b4c&"
import script from "./VideoStoryBlock.vue?vue&type=script&lang=ts&"
export * from "./VideoStoryBlock.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports