import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
// componenets
import Gallery from '@/components/Gallery/Gallery.vue';
let ImageStoryblock = class ImageStoryblock extends Vue {
    mounted() { }
};
__decorate([
    Prop()
], ImageStoryblock.prototype, "block", void 0);
ImageStoryblock = __decorate([
    Component({
        components: {
            Gallery
        }
    })
], ImageStoryblock);
export default ImageStoryblock;
