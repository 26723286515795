import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
let HeadingStoryblock = class HeadingStoryblock extends Vue {
    mounted() {
    }
};
__decorate([
    Prop()
], HeadingStoryblock.prototype, "block", void 0);
HeadingStoryblock = __decorate([
    Component
], HeadingStoryblock);
export default HeadingStoryblock;
