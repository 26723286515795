import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
// components
import Btn from '@/components/Button/Btn.vue';
import CoverImage from '@/components/Elements/CoverImage.vue';
import ProfileCard from '@/components/ProfileCard/ProfileCard.vue';
import ImageStoryblock from '@/views/Stories/Components/ImageStoryBlock/ImageStoryBlock.vue';
import AudioStoryblock from '@/views/Stories/Components/AudioStoryBlock/AudioStoryBlock.vue';
import TextStoryblock from '@/views/Stories/Components/TextStoryBlock/TextStoryBlock.vue';
import HeadingStoryblock from '@/views/Stories/Components/HeadingStoryBlock/HeadingStoryBlock.vue';
import VideoStoryblock from '@/views/Stories/Components/VideoStoryBlock/VideoStoryBlock.vue';
import CreateComment from '@/components/Comments/CreateComment/CreateComment.vue';
import CommentItem from '@/components/Comments/Comment/Comment.vue';
import LabelList from '@/components/Label/LabelList/LabelList.vue';
import CommentList from '@/components/Comments/CommentList/CommentList.vue';
import EngagementFooter from '@/components/Engagements/EngagementFooter/EngagementFooter.vue';
import OverlayBasic from '@/components/OverlayBasic/OverlayBasic.vue';
import MemberLookup from '@/views/Members/Components/MemberLookup.vue';
import SocialShare from '@/components/SocialShare/SocialShare.vue';
let StoryPage = class StoryPage extends Vue {
    constructor() {
        super(...arguments);
        this.coverImage = '';
        this.coverImageCopyright = '';
        // Send link to member
        this.showMemberSelect = false;
        this.isFavourited = false;
        this.shareInfo = {
            url: `${process.env.VUE_APP_URL}story/${this.$route.params.slug}`,
            title: `Africa Geographic - stories`,
            description: 'Get involved in stories through Africa Geographic',
            quote: 'Get involved in stories through Africa Geographic',
            hashtags: `stories,africa,africageographic,club`,
            twitterUser: 'africageo',
        };
    }
    async mounted() {
        await this.$store.dispatch('stories/getStoryBySlug', this.$route.params.slug);
        await this.checkEngagements();
        this.getCoverImage();
        this.getCoverImageCopyright();
        await this.$store.dispatch('engagements/setEngagementType', {
            engagementType: 'story',
            engagementSlug: this.story.slug,
        });
    }
    getCoverImage() {
        if (this.story && this.story.hero_image && this.story.hero_image.public_url) {
            // if the story has a hero image set
            this.coverImage = this.story.hero_image.public_url;
        }
    }
    getCoverImageCopyright() {
        if (this.story && this.story.hero_image && this.story.hero_image.public_url && this.story.hero_image_copyright) {
            this.coverImageCopyright = this.story.hero_image_copyright;
        }
        else {
            return this.coverImageCopyright = '';
        }
    }
    async favourite() {
        await this.$store.dispatch('stories/favourite', this.story.slug);
        await this.checkEngagements();
    }
    // Engagements
    async checkEngagements() {
        if (this.story && this.story.favorite_stories && this.story.favorite_stories.length > 0) {
            this.isFavourited = !!this.story.favorite_stories.filter((user) => user.id === this.profile.id)[0];
        }
        else {
            this.isFavourited = false;
        }
    }
};
__decorate([
    Getter('activeStory', { namespace: 'stories' })
], StoryPage.prototype, "story", void 0);
__decorate([
    Getter('getProfile', { namespace: 'user' })
], StoryPage.prototype, "profile", void 0);
StoryPage = __decorate([
    Component({
        components: {
            Btn,
            CoverImage,
            ProfileCard,
            ImageStoryblock,
            AudioStoryblock,
            TextStoryblock,
            HeadingStoryblock,
            VideoStoryblock,
            CreateComment,
            CommentItem,
            LabelList,
            CommentList,
            EngagementFooter,
            OverlayBasic,
            MemberLookup,
            SocialShare
        },
        metaInfo() {
            return {
                title: 'Africa Geographic | Story',
                meta: [
                    {
                        name: 'story',
                        content: 'Africa Geographic Story',
                    },
                ],
                link: [{ rel: 'canonical', href: `${process.env.VUE_APP_URL}/story/${this.$route.params.slug}` }],
            };
        },
    })
], StoryPage);
export default StoryPage;
