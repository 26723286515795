import { __decorate } from "tslib";
import { Component, Prop, Vue } from 'vue-property-decorator';
import MarkdownFormatter from '@/components/MarkdownFormatter/MarkdownFormatter.vue';
let TextStoryblock = class TextStoryblock extends Vue {
    mounted() {
    }
};
__decorate([
    Prop()
], TextStoryblock.prototype, "block", void 0);
TextStoryblock = __decorate([
    Component({
        components: {
            MarkdownFormatter
        }
    })
], TextStoryblock);
export default TextStoryblock;
